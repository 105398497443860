.nav-links {
  display: flex;
  justify-content: space-around;
  height: 50px;
  color: white;
  background-color: #2d2d2d;
  border-top-style: solid;
  border-color: #474747;
  border-bottom-style: solid;
  border-color: #474747;
}

a:link {
  text-decoration: none;
  font-size: 17px;
  font-weight: bolder;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: white;
  padding-top: 12.5px;
}

a:visited {
  color: white;
}

.company-name {
  font-weight: bolder;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
  height: 50px;
  padding: 20px;
  padding-bottom: 10px;
  background-color: rgb(0, 0, 0);
  color: whitesmoke;
  margin-bottom: 0;
  margin-top: 0;
}

@media (max-width: 800px) {
  .company-name {
    padding: 0%;
    padding-bottom: 0%;
    margin-bottom: 0;
    padding-top: 5%;
    font-size: 25px;
  }
}
