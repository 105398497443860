h2 {
  color: white;
  opacity: 100%;
  font-weight: bolder;
  font-size: xx-large;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 0;
}

h3 {
  color: white;
  opacity: 100%;
  font-weight: 300;
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-top: 0;
}

.Homepage {
  background-color: #688788;
  opacity: 85%;
  text-align: center;
  padding: 75px;
  margin: 200px;
  margin-top: 120px;
  box-shadow: 10px 5px 5px #344546;
}

.page1 {
  background-image: url(/src/assets/house.webp);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px;
}

@media (max-width: 1280px) {
  .page1 {
    width: auto;
    height: auto;
    padding: 100px;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .Homepage {
    padding: 20px;
    margin: 0px;
    width: auto;
    margin-top: 10px;
  }

  h2 {
    font-size: 15px;
  }

  h3 {
    font-size: 10px;
  }
}
