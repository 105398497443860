.roof-wrapper {
  max-width: 500px;
  margin: 20px auto;
  padding: 50px;
  filter: brightness(90%);
  padding-top: 5px;
}

.roof-wrapper h2 {
  color: black;
  font-weight: bolder;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1280px) {
  .roof-wrapper h2 {
    font-size: xx-large;
    margin-top: 0;
  }

  .roof-wrapper {
    position: relative;
    padding: 20px;
  }
}
