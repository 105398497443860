.description-h2 {
  color: black;
  padding-top: 70px;
}

#phone-number {
  color: black;
  font-weight: 700;
  font-size: 16px;
}

#phone-number:hover {
  text-decoration: underline;
}

.description-h3 {
  color: black;
  font-weight: bold;
  font-size: large;
  text-align: center;
}

.description-p {
  font-style: italic;
}

/* Float four columns side by side */
.description-column {
  float: left;
  width: 100%;
  padding: 0 100px;
}

/* Remove extra left and right margins, due to padding in columns */
.description-row {
  margin: 0 -5px;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

/* Clear floats after the columns */
.description-row:after {
  content: "";
  display: table;
  clear: both;
}

/* Style the counter cards */
.description-card {
  padding: 16px;
  text-align: center;
}

ul {
  text-align: left;
  padding-left: 70px;
}

li {
  margin: 10px;
}

/* Responsive columns - one column layout (vertical) on small screens */
@media only screen and (max-width: 1200px) and (min-width: 280px) {
  .description-column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }

  .description-card {
    margin: 0;
    width: 100%;
    height: auto;
    padding: 0;
  }

  .description-row {
    width: 100%;
    height: auto;
    padding-top: 0%;
    margin: 0;
    text-align: center;
  }

  .description-row {
    display: flex;
    flex-direction: column;
  }

  .description-h2 {
    padding-top: 0px;
  }

  .description-h3 {
    font-size: 100%;
  }

  .description-p {
    font-size: 100%;
  }

  ul {
    padding-left: 30px;
  }
}
