.testimonial-container {
  width: 100%;
  position: absolute;
  top: 60%;
  left: 60%;
  transform: translate(-50%, -50%);
  grid-template-columns: repeat(6, 1fr);
  padding-top: 775px;
}

.column {
  color: black;
  font-weight: 500;
  float: left;
  width: 25%;
  padding: 0 0 20 0;
  margin-right: 50px;
  box-shadow: 10px 5px 5px #1f1f1f;
}

.row {
  margin: 0 -5px;
  position: absolute;
  margin-top: 420px;
  padding-bottom: 50px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 50px;
  text-align: center;
  background-image: url(/src/assets/testimonials-background.gif);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 25% 50%;
  filter: brightness(90%);
}

.card h3 {
  color: black;
}

.testimonial-heading {
  color: black;
  display: block;
  justify-content: center;
  padding-bottom: 5%;
  padding-top: 0;
}

@media only screen and (max-width: 1200px) and (min-width: 280px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 5%;
  }

  .testimonial-container {
    top: 0;
    left: 50%;
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 0%;
    position: relative;
    padding-top: 0;
  }

  .card {
    margin: 0;
    width: auto;
    height: 25%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .row {
    width: 90%;
    height: auto;
    padding-top: 0%;
    margin: 0;
  }

  p {
    font-size: 100%;
  }

  .testimonial-heading {
    padding-bottom: 10px;
    font-size: 150%;
  }

  .card h3 {
    font-size: 100%;
  }
}
