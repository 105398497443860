.bathroom-container {
  position: relative;
  max-width: 500px;
  margin: 20px auto;
  padding: 50px;
  filter: brightness(90%);
  padding-top: 5px;
}

.bathroom-heading {
  color: black;
  padding-top: 5px;
}

@media only screen and (max-width: 1280px) {
  .bathroom {
    display: flex;
    justify-content: center;
    width: 75%;
    padding: 0%;
    margin: 0%;
  }

  .bathroom img {
    width: 100%;
    height: 400px;
    padding: 30px;
    margin: 0;
  }

  .bathroom-container {
    position: relative;
    padding: 20px;
  }

  .bathroom-heading {
    font-size: xx-large;
    margin-top: 0;
  }
}
