.contact-header {
  padding-bottom: 20px;
  font-weight: bold;
  margin-top: 0;
}

.contact-container {
  padding: 50px;
  border-radius: 10px;
  background-color: #688788;
  box-shadow: 10px 5px 5px #1f1f1f;
  position: absolute;
  display: grid;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.contact-link {
  color: whitesmoke;
  padding-top: 10px;
  padding-bottom: 10px;
}

label {
  color: whitesmoke;
  font-weight: lighter;
}

.page4 {
  background-image: url(/src/assets/KMINovDec2020_017-65d7c274641345d49de8d29746d666a6.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px;
  width: 100vw;
  height: 100vh;
}

@media (max-width: 1280px) and (min-width: 280px) {
  .page4 {
    width: auto;
    display: flex;
  }

  .contact-container {
    padding: 5%;
    position: absolute;
    display: grid;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .contact-header {
    font-size: 20px;
  }
}
